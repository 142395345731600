export default {
    list: {
        title: "zones",
        name: 'zones',
        class: "min-h-screen flex-1 mt-1 bg-gray-100 px-3 items-center",
        options: {
            defaultPageSize: 5,
            actions: {
                isActive: true,
                name: "Actions",
                buttons: [{
                    text: "add_zone",
                    icon: "add",
                    link: '/zones/new',
                    color: 'primary'
                }],
            }
        },
        columns: [{
                name: "name",
                elements: [{
                    items: [{
                        class: "",
                        name: "name",
                        type: "text",

                    }, ],
                }, ]
            },
            {
                name: "country",
                class: "text-center",
                elements: [{
                    items: [{
                        class: "text-center",
                        name: "country",
                        type: "text",
                    }, ],
                }, ]
            },
            {
                name: "price",
                class: "text-center",
                elements: [{
                    items: [{
                        class: "text-center",
                        name: "price",
                        type: "text",
                    }, ],
                }, ]
            },
            {
                name: "number_of_cities",
                class: "text-center",
                elements: [{
                    items: [{
                        class: "text-center",
                        name: "cities",
                        array: true,
                        type: "text",
                    }],
                }, ]
            },
        ],
        actions: {
            name: "actions",
            width: "10px",
            class: "justify-center",
            elements: [{
                    icone: "edit",
                    color: "success",
                    role: true,
                    key: 'update',
                    link: '/zones/edit',
                    tooltip: "Update",
                },
                {
                    icone: "delete",
                    color: "danger",
                    role: true,
                    key: 'delete',
                    tooltip: "Delete",
                }
            ],
        },
        filters: {
            name: 'zones',
            elements: {
                search: {
                    options: {
                        icon: "search",
                        label: "search",
                        class: "",
                        hint: "search_zone",
                    },
                    value: null,
                    type: "search",
                    validate: {
                        trim: true,
                        required: false,
                        minlength: 0,
                    }
                },
                champs: [],
            },
        },
    },
}